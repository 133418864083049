interface SubmitButtonProps {
  label?: string;
  disabled?: boolean;
}
const SubmitButton = ({ label, disabled }: SubmitButtonProps) => {
  return (
    <>
      <button
        className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold
        text-white shadow-sm
       hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        disabled={disabled}
        type="submit"
      >
        {label ?? "Sauvegarder"}
      </button>
    </>
  );
};

export default SubmitButton;
